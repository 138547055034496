import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 160px 0 110px 0;
  padding: 70px 0;
  text-align: center;
`;

const Lets = styled.p`
  font-size: 30px;
  color: #4d4d4d;
  font-weight: 500;
  margin-bottom:20px;
`;

const Requt = styled.p`
  font-size: 18px;
  color: #00a97c;
  margin: 0;
  padding: 0;
`;

const Soon = styled.p`
  font-size: 16px;
  color: #b4b4b4;
  margin-top: 10px;
`;
const Thankyou = () => {


        

    return (
        <Container>
        <div>
          <Lets>Let's build together!</Lets>
          <Requt>Thank you for requesting Consultation Call.</Requt>
          <Soon>Our experts will reach you out soon.</Soon>
        </div>
      </Container>
        
     
    );
};

export default Thankyou;

