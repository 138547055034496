import React from 'react';
import BelivingCompanies from './BelivingCompanies';
import BussinessService from './BussinessService';
import ClassServices from './ClassServics';
import Clients from './Clients';
import Coustumerstories from './Coustumerstories';
import HeroSection from './HeroSection';
import Services from './Services';
import MetaverseSection from './MetaverseSection';
import ConnectMessage from '../../Component/ConnectMessage';
import HeroSectionNew from './HeroSectionNew';
import MapLocation from '../ContactUs/MapLocation';

const Home = () =>{
    return(
        <div style={{overflow:"hidden"}}>
           {/* <HeroSection/> */}
           <HeroSectionNew />
           <BelivingCompanies />
           <BussinessService />
           <Services/> 
           <ClassServices />
           <MetaverseSection />
           <Clients />
           <Coustumerstories />
           {/* <MapLocation /> */}
           <ConnectMessage /> 

        </div>
    );
};

export default Home;