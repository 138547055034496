import React from 'react';
import styled from 'styled-components';
import { Button, Container } from '../../Component/styledComponents/styledComponents';
import LicenceImage from '../../Component/Images/licence.jpg';
import { 
    ButtonWrapper, 
    Heading, 
    HeadingSection, 
    HeadingWrapper, 
    HeadText, 
    Icon, 
    Image, 
    Line, 
    List, 
    Root, 
    Text, 
    TextTab, 
    Wrapper } from '../../Component/styledComponents/TermsAndConditionStyledComponents';
import { useNavigate } from 'react-router-dom';


function PrivacyAndPolicy(props) {
    const navigate = useNavigate();
    return (
        <Root>
        <Container>
            <Wrapper>
                <HeadingSection>
                    <HeadingWrapper>
                        <Heading>Privacy</Heading>
                        <Line color="#68e7f2"></Line>
                    </HeadingWrapper>
                    &
                    <HeadingWrapper>
                        <Heading>Policy</Heading>
                        <Line color="#69e2c1"></Line>
                    </HeadingWrapper>
                </HeadingSection>
                <TextTab>This policy is effective as of 28 July 2022.</TextTab>
                <Text>BastionEx In hereinafter referred as ‘BastionEx’, ‘we’, ‘us’ or ‘our’ is committed to respect your privacy and choices. 
                    The privacy statement highlights our privacy practices regarding Personal Information that we collect and process depending on 
                    your association with BastionEx and nature of processing activity in compliance to applicable data privacy regulations.
                </Text>
                <Image src={LicenceImage} />
                <HeadText>Website Privacy Statement:</HeadText>
                <Text>This privacy statement highlights our privacy practices regarding Personal Information that we collect and process
                     about you through this website, www.BastionEx.com (the “Site”) and also for those Personal Information that you provide 
                     us while participating in our events and campaigns.
                </Text>
                <HeadText>Personal Information we collect and process and how we use it</HeadText>
                <Text style={{marginTop:"10px"}}><strong>1.Personal Information that we process</strong></Text>
                <Text>
                   For the purposes of this privacy statement, 'Personal Information' is any data which relates to an individual
                   who may be identified from that data, or from a combination of a set of data, and other information which is in 
                   possession of BastionEx.
                </Text>
                <Text>We collect the following categories of Personal Information:</Text>
                <List>
                    <Icon /><Text>Information that you provide via our website, including information you provide when you register on our website e.g. name, email address, designation, company, country, and telephone number;</Text>
                </List>

                <List>
                    <Icon /><Text>Information about your computer and about your visits to and use of this Site, such as your Internet 
                        Protocol (IP) address, demographics, your computers’ operating system, and browser type and information collected 
                        via cookies</Text>
                </List>


                <List><Icon/><Text>Cookie related details are provided in the Cookie Policy.</Text></List>
                {/* <Image src={LicenceImage} /> */}
                <Text style={{marginTop:"10px"}}><strong>2.Use of your Personal Information</strong></Text>
                <Text>We use your Personal Information for the following purposes:</Text>
                <List>
                    <Icon/><Text>to provide better usability, troubleshooting and site maintenance;</Text>
                </List>
                <List>
                    <Icon/><Text>to understand which parts of the website are visited and how frequently;</Text>
                </List>
                <List>
                    <Icon/><Text>to create your user ID;</Text>
                </List>
                <List>
                    <Icon/><Text>to identify you once you register on our website;</Text>
                </List>
                <List>
                    <Icon/><Text>to contact you and respond to your questions or requests; and</Text>
                </List>
                <List>
                    <Icon/><Text>to provide access to desirable content based on your preferences.</Text>
                </List>
                {/* <Image src={LicenceImage} /> */}
                <Text style={{marginTop:"10px"}}><strong>3.Legal basis of the processing</strong></Text>
                <Text>We process your Personal Information when it is necessary for the performance of a contract to which you are the 
                    party or in order to take steps at your request prior to entering into a contract. This applies in any case where we
                    provide services to you pursuant to a contract, such as when you use our website or process your registration on the 
                    website. If you do not provide the Personal Information that we need in order to provide our services, we will not be
                    able to provide our services to you. We process your Personal Information for the performance of a contract in the 
                    following circumstances:
                </Text>
                <List>
                    <Icon/><Text>to provide better usability, troubleshooting and site maintenance; and</Text>
                </List>
                <List>
                    <Icon/><Text>to create your user ID.</Text>
                </List>
                <Text>We process your Personal Information when it is necessary for the purposes of a legitimate interest pursued by us or 
                    a third party (when these interests are not overridden by your data protection rights). This applies in the following 
                    circumstances:
                </Text>
                <List>
                    <Icon/><Text>to identify you once you register on our website; and</Text>
                </List>
                <List>
                    <Icon/><Text>to contact you and respond to your questions or requests;</Text>
                </List>
                <List>
                    <Icon/><Text>to understand which parts of the website are visited and how frequently; and</Text>
                </List>
                <List>
                    <Icon/><Text>to provide access to desirable content based on your preferences.</Text>
                </List>
                <Text>We process your Personal Information with your consent for the below purpose(s). Where we process Personal Data 
                    based on consent, your consent is revocable at any time.
                </Text>
                <List><Icon/><Text>To know your interest of participation in ideathons or other competitions</Text></List>
                <List><Icon/><Text>To contact you in furtherance to your participation in the ideathons or other competitions</Text></List>
                <Text>As a participant of the competition, to publish your name and corresponding details to make them available to larger
                     audience on the Internet
                </Text>
                <List>
                    <Icon/><Text>to process job application and alumni related requests, more details about which are available on corresponding 
                    portals of this website.</Text>
                </List>
                <List>
                    <Icon/><Text>To know your interest of participation in ideathons or other competitions</Text>
                </List>
                <List>
                    <Icon/><Text>As a participant of the competition, to publish your name and corresponding details to make them available to larger audience on the Internet</Text>
                </List>
                <Image src={LicenceImage} />
                <HeadText>Consequences of not providing Personal Information</HeadText>
                <Text>If you choose not to provide your Personal Information that is mandatory to process your request, we may not be able
                     to provide the corresponding service.</Text>
                <Image src={LicenceImage} />
                <HeadText>Data recipients, transfer, and disclosure of Personal Information</HeadText>
                <Text>BastionEx does not share your Personal Information with third parties for their direct marketing purposes.</Text>
                <Text>We share your Personal Information within</Text>
                <List><Icon/><Text>BastionEx or with any of its subsidiaries</Text></List>
                <List><Icon/><Text>Business partners</Text></List>
                <List><Icon/><Text>Service vendors</Text></List>
                <List><Icon/><Text>Authorized third-party agents; or</Text></List>
                <List><Icon/><Text>Contractors.</Text></List>
                <Text>BastionEx does not sell your Personal Information to third parties.</Text>
                <Text>In general, BastionEx, domiciled in Noida, is the data controller processing your Personal Information. The following
                     applies only where the data controller processing your Personal Information is domiciled in the European 
                     Economic Area (“EEA”):
                </Text>
                <Text>We transfer Personal Information to countries outside the EEA to third parties, including to countries which have 
                different data protection standards to those which apply in the EEA. The locations of BastionEx group companies is set out 
                here. Our service providers are primarily located in USA, Canada, Australia, Singapore, Hong Kong, India and UK. Where 
                service providers process your Personal Information in countries deemed adequate by the European Commission, we rely on 
                the European Commission's decision to protect your Personal Information.</Text>
                <Text>For transfers to BastionEx group companies and service providers outside the EEA, we use standard contractual clauses or
                    rely on a service provider's (EU data protection authority approved) corporate rules that are in place to protect your
                    Personal Information. Please contact us as set out below if you would like to obtain a copy of the methods used. When
                    required, BastionEx discloses Personal Information to external law enforcement bodies or regulatory authorities, in 
                    order to comply with legal obligations.
                </Text>
                <Image src={LicenceImage} />
                <HeadText>Do Not Track</HeadText>
                <Text>Do Not Track (DNT) is a privacy preference that users can set in some web browsers, allowing users to opt out of 
                    tracking by websites and online services. At the present time, the World Wide Web Consortium (W3C) has not yet 
                    established universal standards for recognizable DNT signals and therefore, BastionEx and the Site do not recognize DNT.
                </Text>
                <Image src={LicenceImage} />
                <HeadText>Your Rights</HeadText>
                <Text>Subject to the laws of your country, you have the right to access, correct, delete or transfer your Personal Information that
                 we hold, including your profile and preferences. You also have the right to object to certain processing and, where we have asked
                 for your consent to process you’re Personal Information, to withdraw this consent. Where we process your Personal Information 
                 because we have a legitimate interest in doing so (as explained above), you also have a right to object to this. These rights may
                 be limited in some situations – for example, where we can demonstrate that we have a legal requirement to process your Personal Information.
                 </Text>
                 <Text>You can assert your rights in the corresponding sections where such information was provided or by contacting us at 
                privacy@bastionexinfotech.com</Text>
                <Image src={LicenceImage} />
                <HeadText>Data security</HeadText>
                <Text>BastionEx adopts reasonable and appropriate security practices and procedures including administrative, physical
                 security, and technical controls in order to safeguard your Personal Information.</Text>
                 <Image src={LicenceImage} />
                <HeadText>Data retention</HeadText>
                <Text>Personal Information will not be retained for a period more than necessary to fulfill the purposes outlined in this 
                privacy statement, unless a longer retention period is required by law or for directly related legitimate business 
                purposes.</Text>
                <Image src={LicenceImage} />
                <HeadText>Contact your Local Data Protection Authority</HeadText>
                <Text>Depending on the laws of the countries where you reside, if you are unhappy with how we safeguard your personal data,
                 you have the right to bring a complaint to your local data protection authority.</Text>
                 <Image src={LicenceImage} />
                <HeadText>Linked websites</HeadText>
                <Text>Our privacy practices regarding Personal Information that we collect and store about you through our portals such as 
                Recruitment and Global Alumni will be as per the privacy policy of those portals.</Text>
                <Text>BastionEx provides links to third-party websites and services. However, BastionEx is not responsible for the privacy statements,
                 practices, or the contents of such third-party websites.</Text>
                 <Image src={LicenceImage} />
                <HeadText>Children’s Privacy</HeadText>
                <Text>The Site does not knowingly solicit or collect personally identifiable information online from children under the 
                age of 13 without prior verifiable parental consent. If BastionEx learns that a child under the age of 13 has submitted 
                personally identifiable information online without parental consent, it will take all reasonable measures to delete such
                information from its databases and to not use such information for any purpose (except where necessary to protect the
                safety of the child or others as required or allowed by law). If you become aware of any personally identifiable 
                information we have collected from children under 13, please email us at privacy@bastionexinfotech.com.</Text>
                <Text>Minors under 18 years of age may have the personal information that they provide to us deleted by sending an email to
                privacy@bastionexinfotech.com requesting deletion. Please note that, while we make reasonable efforts to comply with such 
                requests, deletion of your personal information does not ensure complete and comprehensive removal of that data from all 
                systems.</Text>
                <Image src={LicenceImage} />
                <HeadText>How to contact us</HeadText>
                <Text>If you have any questions regarding our privacy practices or this privacy statement, or to request this privacy statement
                 in another format, please contact us at:</Text>
                 <Text>Email: privacy@bastionexinfotech.com</Text>
                <ButtonWrapper>
                       <Button 
                       bgcolor="#00a97c"
                       color="#fff"
                       top="15px"
                       right="30px"
                       bottom="15px"
                       left="30px"
                       radius="30px"
                       onClick={() => (navigate("/contact_us"))}
                       >
                        Contact Us  
                       </Button>
                </ButtonWrapper>
            </Wrapper>
        </Container>
    </Root>
    );
}

export default PrivacyAndPolicy;