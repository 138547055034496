import { Button} from "@mui/material";
import React, { useState } from "react";
import circle from './Images/circle.png';
import hiboy from "./Images/hi-boy.png";
import hiback from "./Images/hi-back.png";
import styled, { keyframes } from "styled-components";
import { Container } from "./styledComponents/styledComponents";
import axios from "axios";
import SucessPopup from "./SucessPopup";
import EmailAlreadyExist from "./EmailAlreadyExist";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import CircularLoader from "../Component/CircularLoader";
import { environmentVariables } from "../config/env.config";
import { useNavigate } from "react-router-dom";

const baseUrl = environmentVariables?.apiUrl;
const Root = styled.div`
background-color:#0c0c0c;
padding-bottom:100px;
padding-top:100px;
@media(max-width:767px){
  padding:30px 0;
}
`;
const Input = styled.input`
border-radius:31px;
height:3rem;
width: 95%;
border:1px solid #808080;
background:none;
color:#fff;
padding-left:1.5rem;
outline:none;
${(p) => p.right && `
 margin-right:20px;
`};
 @media(max-width:767px){
  margin-bottom: 20px;
  ${(p) => p.right && `
 margin-right:0px;
`};
}   
`;
const ImageWrapper = styled.div`
      margin-left: 36rem;
      margin-top: -9rem;
`;
const animate = keyframes`
0%{
  transform: translateX(0);
 }
 25%{
  transform: translateX(30px);
 }
 50%{
  transform: translateX(-30px);
 }
 100%{
  transform: translateX(0);
 }
`;

const Move = styled.img`
  z-index: 2;
  /* margin-left:-45rem; */
  margin-Top:10rem;
  position:absolute;
  animation-timing-function: ease-in;
  animation: ${animate} 5s infinite linear;
  right:3rem;
`;
const Form = styled.form`
width:100%;
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
`;
const UpperImage = styled.img`
          z-index: 3;
          /* margin-left: -31rem; */
          margin-top: 4rem;
          position: absolute;
          height:422px;
          right:0;
          filter: brightness(0.3);
`;
const CircleImage = styled.img`
          position:absolute;
          z-index: 1;
          height: 21rem;
          right:5rem;
          top:-1.9rem;
`;
const FormWrapper = styled.div`
width:100%;
display:flex;
justify-content: space-between;
align-items: center;
@media(min-width: 767px){
  margin-bottom:20px;
}
@media(max-width:767px){
  flex-direction:column;
}
`;
const Div = styled.div`
width:60%;
@media(max-width:767px){
  width:100%;
}
`;
const ImageSection = styled.div`
width:40%;
position:relative;
@media(max-width:767px){
  display: none;
}
`;

const ButtonWrapper = styled.div`

`;
const Heading = styled.h6`
margin: 0;
font-size:40px;
font-weight:900;
color:#fff;
margin-bottom:30px;
@media(max-width:767px){
  font-size:30px;
}
`;
// const ErrorMessage = styled.div`
// color:red;
// padding:5px 0 10px 0;
// `;
const Select = styled.select`
  width: 95%;
  background: #0c0c0c;
  border-radius: 31px;
  margin-right: 13px;
  color: gray;
  padding-left: 5px;
  font-size: 14px;
  border: 1px solid #808080;
  padding: 14px 10px;
  option {
    color: gray;
    border-radius: 10px !important;
    /* background: white; */
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
    border-radius: 10px;
 
  }
  @media(max-width: 767px){
      margin-bottom: 20px;
      margin-right: 0;
    }
`;

const ErrorMsg = styled.div`
  font-size: 12px;
  color: #ff0000c2;
  padding-top: 2px;
`;

const InputWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`;

function ConnectMessage() {
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [phone,setPhone] = useState('');
  const [companyname, setCompanyname] = useState('');
  const [profession, setProfession] = useState('');
  const [select, setSelect] = useState("");
  const [message,setMessage] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);

  const [showPopup,setShowPopup] = useState(false);
  const [responseMessage,setResponseMessage] = useState('');
  const [sucessResponse,setSucessResponse] = useState();
  const [emailError, setEmailError] = useState();
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();


 
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Name must contain only letters")
      .min(3, "must be at least 3 char")
      .max(50, " must be less than 50 char")
      .required("Full Name is required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Accept only valid email"
      )
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .min(10, "Required Valid Phone number")
      .max(12, " Not Accept more than 12 Number")
      .required("Contact Number is required"),

    companyname: Yup.string()
      .min(5, "must be at least 5 char")
      .max(100, " must be less than 100 char")
      .required("Company Name is required"),

    select: Yup.string().required("Company Size is required"),

    profession: Yup.string()
      .min(3, "must be at least 3 char")
      .max(100, " must be less than 50 char")
      .required("Designation is required"),
    message: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: name,
      email: email,
      phone: phone,
      select: select,
      companyname: companyname,
      profession: profession,
      message: message,
    },
    validationSchema,
    // onSubmit: (values) => {
    //   console.log(values);
    // },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const phoneAsString = String(formik.values.phone);
   // Extract query parameters from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const queryString = urlParams.toString();
    let config = {
      method: "post",
      url: `${baseUrl}api/v1/info/saveContactInfo`,
      data: { ...formik.values, phone: phoneAsString,  keywords: queryString},
    };

    axios
      .request(config)
      .then((response) => {
        formik.resetForm();
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setCompanyname("");
        setProfession("");
        setSelect("");
        setIsSubmit(false);
        navigate("/thankyou");
        // Swal.fire({
        //   title: "Success",
        //   text: `Thank you for reaching out to us! 
        //   Team will get back to you soon`,
        //   icon: "success",
        // });
        // console.log(response.data);
        formik.resetForm();
      })
      .catch((error) => {
        console.log(error);
        setIsSubmit(false);
        Swal.fire({
          icon: "error",
          text: "Something went wrong!",
        });
      });
  };
  return (
    <Root>
       {/* {showPopup && <SucessPopup 
       showPopup={showPopup} 
       setShowPopup={setShowPopup} 
       responseMessage={responseMessage}
       sucessResponse={sucessResponse}
      />} */}
        {/* {popup && (
        <EmailAlreadyExist
          responseMessage={responseMessage}
          sucessResponse={sucessResponse}
          showPopup={popup}
          // emailError={emailError}
          setShowPopup={setPopup}
        />
      )} */}
      <Container>
            <Div>
              <Heading>
              Contact Us for <span style={{color:"rgb(193 8 68)"}}> Consultation </span> Call.
              </Heading>
                <Form >
                  <FormWrapper>
                <InputWrapper>
                
                <Input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.name && formik.errors.name && (
                  <ErrorMsg>{formik.errors.name}</ErrorMsg>
                )}

                </InputWrapper>
                    
                    <InputWrapper>
                    <Input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.email && formik.errors.email && (
                  <ErrorMsg>{formik.errors.email}</ErrorMsg>
                )}
                    </InputWrapper>
                    <InputWrapper>
                    <Input
                  type="number"
                  name="phone"
                  placeholder="Phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.phone && formik.errors.phone && (
                  <ErrorMsg>{formik.errors.phone}</ErrorMsg>
                )}
                    </InputWrapper>
                  </FormWrapper>
                  <FormWrapper>
                <InputWrapper>
                <Input
                  type="text"
                  placeholder="Company Name"
                  name="companyname"
                  value={formik.values.companyname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.companyname && formik.errors.companyname && (
                  <ErrorMsg>{formik.errors.companyname}</ErrorMsg>
                )}</InputWrapper>
                 <InputWrapper>
                 <Select 
                    name="select"
                    value={formik.values.select}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                   required>
                    <option value="" hidden>
                     Company Size
                    </option>
                    <option value="10">0-10 Employees</option>
                    <option value="25">11-25 Employees</option>
                    <option value="50">26-50 Employees</option>
                    <option value="100">51-100 Employees</option>
                    <option value="150">100+ Employees</option>
                  </Select>
                  {formik.touched.select && formik.errors.select && (
                  <ErrorMsg>{formik.errors.select}</ErrorMsg>
                )}
                 </InputWrapper>
                  <InputWrapper>
                  <Input
                  type="text"
                  placeholder="Designation"
                  name="profession"
                  value={formik.values.profession}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.profession && formik.errors.profession && (
                  <ErrorMsg>{formik.errors.profession}</ErrorMsg>
                )}
                  </InputWrapper>
                  </FormWrapper>
                  <FormWrapper>
                    <Input
                       type="text"
                       placeholder="Message...."
                       name="message"
                       value={formik.values.message}
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       style={{width:"98%"}}
                    />
                  </FormWrapper>
                  {/* {error && <ErrorMessage>All Field are required.</ErrorMessage>} */}
                  <ButtonWrapper>
                  <Button 
                  style={{
                    backgroundColor: "#fff",
                    color: "#000", fontSize: "1.3rem",
                    borderRadius: "30px", width: "9rem",
                    height: "3rem",
                    textTransform: "capitalize",
                  }}
                  onClick={handleSubmit} 
                  // disabled={!formik.isValid}
                  disabled={
                     !formik.dirty
                    }
                   >
                   {isSubmit ? <CircularLoader size={20} /> : "SUBMIT"}
                  </Button>
                  </ButtonWrapper>
                </Form>
            </Div>
            <ImageSection >
              <ImageWrapper >
                <CircleImage src={circle} alt="Contact Bastionex Infotech" />
                <Move src={hiboy} alt="Contact Bastionex Infotech" />
                <UpperImage src={hiback} alt="Contact Bastionex Infotech" />
              </ImageWrapper>
            </ImageSection>
      </Container>
    </Root>
  );
}
export default ConnectMessage;
