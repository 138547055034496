import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import Background from "../../Component/Images/bike-racing-bg.png";
import backBuildingimg from "../../Component/Images/backbuilding.svg";
import Roadbackground from "../../Component/Images/rode.svg";
import bikeimg from "../../Component/Images/greenbike.svg";
import bikestp from "../../Component/Images/bikestrip.svg";
import RedBike from "../../Component/Images/red-bike.png";
import YellowBike from "../../Component/Images/yellow-bike.png";

const Root = styled.div`
  height: 100%;
  background-color: #061a42;
  position: relative;
  z-index: 2;
  margin-bottom: 12rem;
  @media (max-width: 1058px) {
    height: auto;
  }
  @media (max-width: 425px) {
    height: 16rem;
  }
  @media (max-width: 375px) {
    height: 14rem;
  }
`;
const BgImageWrapper = styled.div``;
const Container = styled.div``;
const Span = styled.span`
  padding-top: 1rem;
`;
const BgImage = styled.img`
  width: 100%;
`;
const ChooseColor = styled.div`
  margin: 2rem 0rem 0rem 71rem;
  position: absolute;
  top: 0px;
  @media (max-width: 1255px) {
    margin: 2rem 0rem 0rem 64rem;
  }

  @media (max-width: 1120px) {
    margin: 2rem 0rem 0rem 58rem;
  }
  @media (max-width: 1058px) {
    margin-left: 54rem;
  }
  @media (max-width: 1042px) {
    margin: 2rem 0rem 0rem 50rem;
  }
  @media (max-width: 768px) {
    margin: 2rem 0rem 0rem 38rem;
  }
  @media (max-width: 425px) {
  display: none;
  }


`;
const CircleText = styled.text`
  color: white;
  margin-top: 10rem;
  font-size: 0.8rem;
  padding-left: 0.5rem;
`;

const Circle = styled.div`
  display: flex;
  column-gap: 1rem;
  column-gap: 0.7rem;
  padding: 0.3rem 0rem 0rem 0.4rem;
  @media (max-width: 425px) {
    column-gap: 0.7rem;
    column-gap: 0.3rem;
    padding: 0.3rem 0rem 0rem 0.9rem;
  }
`;
const GreenCircle = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  border: 0.8px solid white;
  background-color: #0bffdf;
  border-radius: 2rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

  @media (max-width: 425px) {
    height: 1rem;
    width: 1rem;
  }
`;
const RedCircle = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  border: 0.8px solid white;
  background-color: #ff0b27;
  border-radius: 2rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  @media (max-width: 425px) {
    height: 1rem;
    width: 1rem;
  }
`;
const YellowCircle = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  border: 0.8px solid white;
  background-color: #ffba0b;
  border-radius: 2rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  @media (max-width: 425px) {
    height: 1rem;
    width: 1rem;
  }
`;
const TextWrapper = styled.div`
  position: absolute;
  top: 0px;
  padding: 4rem 0rem 0rem 15rem;

  @media (max-width: 425px) {
    margin: -2rem 0rem 0rem -1rem;
  }
  @media (max-width: 375px) {
    margin: -3rem 0rem 0rem -5rem;
  }
`;

const SmallText = styled.text`
  color: white;

  @media (max-width: 1058px) {
    margin-left: -4rem;
  }
  @media (max-width: 1042px) {
    margin-left: -6rem;
  }
`;
const BoldText = styled.text`
  text-align: center;
  @media (max-width: 375px) {
    margin: -2rem 0rem 0rem -4rem;
  }
`;
const FirstBoldText = styled.text`
  font-size: 7.3rem;
  font-family: roboto;
  font-weight: 600;
  color: #00a97c;
  transform: translate(-50%, -50%);
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;

  @media (max-width: 1058px) {
    margin-left: -4rem;
  }
  @media (max-width: 1042px) {
    margin-left: -6rem;
  }
  @media (max-width: 768px) {
    font-size: 5rem;
  }
  @media (max-width: 425px) {
    font-size: 2rem;
    padding-right: 5rem;
    margin: -3rem 0rem 0rem -3rem;
  }
`;
const SecondBoldText = styled.text`
  font-size: 7.3rem;
  font-family: roboto;
  font-weight: 600;
  color: #00a97c;
  transform: translate(-50%, -50%);
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  text-align: center;
`;

const animate = keyframes`
0%{
  transform: translateX(0);
 }
 25%{
  transform: translateX(20px);
 }
 50%{
  transform: translateX(-20px);
 }
 100%{
  transform: translateX(0);
 }
 
`;

const BackBuildingimg = styled.div`
  width: 98%;
  position: absolute;
  top: 0px;
  animation: ${animate} 10s linear infinite;

  @media (max-width: 1058px) {
    margin-top: -3.6rem;
  }
  @media (max-width: 768px) {
    margin-top: -7.1rem;
  }
  @media (max-width: 425px) {
    margin-top: -11.5rem;
  }
  @media (max-width: 375px) {
    margin-top: -12.2rem;
  }
`;
const Image = styled.img`
  height: 34rem;
  width: 100%;
`;

const Roadimg = styled.div`
  position: absolute;
  top: 0px;
`;
const RoadImage = styled.img`
  margin: 26rem 0rem 0rem 0rem;
  height: 28rem;
  width: 100%;

  @media (max-width: 1058px) {
    margin: 17rem 0rem 0rem 0rem;
    height: 28rem;
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-top: 9.1rem;
  }
  @media (max-width: 425px) {
    margin-top: -1.2rem;
  }
  @media (max-width: 375px) {
    margin-top: -2.6rem;
  }
`;

const animated = keyframes`
  0% {
      transform: translate(64px, 161px) rotate(-4deg);
    }
    100% {
      transform: translate(64px, 161px) rotate(4deg);
    }

`;

const Bikeimg = styled.div`
  position: absolute;
  top: 0px;
  animation: ${animated} 5s linear alternate infinite;
`;
const BikeImage = styled.img`
  margin: 22rem 0rem 0rem 26rem;
  height: 22rem;
  width: 100%;
  z-index: 5;
  transform-origin: left;
  transform: translate(-57px, -180px) rotate(0deg);
  animation: biker 5s infinite alternate linear;

  @media (max-width: 1058px) {
    margin: 14rem 0rem 0rem 18.5rem;
  }
  @media (max-width: 768px) {
    margin: 5rem 0rem 0rem 11.5rem;
  }

  @media (max-width: 425px) {
   
    height: 12rem;
    width: 5rem;
    margin-left: 8.7rem;
  }
  @media (max-width: 375px) {
    height: 10rem;
    width: 4rem;
    margin-left: 9.7rem;
  }
`;
const Bikestrip = styled.div`
  position: absolute;
  top: 0px;
  @media (max-width: 1440px) {
    margin: 0.7rem 0rem 0rem 0rem;
  }
  @media (max-width: 1058px) {
    margin: -9.4rem 0rem 0rem -11rem;
  }
  @media (max-width: 768px) {
    margin: -18.8rem 0rem 0rem -21rem;
  }
  @media (max-width: 425px) {
    margin: 0rem 0rem 0rem -1.1rem;
  }
  @media (max-width: 375px) {
    margin-left: -0.9rem;
    margin-top: -2rem;
  }
`;
const Bikerace = styled.img`
  margin: 33.8rem 0rem 0rem 27.1rem;
  height: 12rem;
  width: 100%;
  z-index: 4;

  @media (max-width: 425px) {
    margin: 12.2rem 0rem 0rem 8rem;
    height: 4rem;
    width: 100%;
    z-index: 4;
  }
`;

const LineWrapper = styled.div``;
const FirstLine = keyframes`
0%{
  transform: translateY(0);
  opacity : 1;
 }
100%{
  transform: translateY(20px);
  opacity: .9;
 }
  `;

const Line1 = styled.div`
  position: absolute;
  background-color: white;
  height: 1px;
  width: 100%;
  animation: FirstLine linear infinite;
  top: 33rem;
  animation: ${FirstLine} 1s linear infinite;
  opacity: 1;
  

  @media (max-width: 1058px) {
    margin-top: -7rem;
  }
  @media (max-width: 768px) {
    margin-top: -14.1rem;
  }

  @media (max-width: 425px) {
    margin-top: -22.5rem;
  }
  @media (max-width: 375px) {
    margin-top: -23.7rem;
  }
`;

const SecondLine = keyframes`
0%{
  transform: translateY(0);
  opacity : .9;
 }
100%{
  transform: translateY(20px);
  opacity: .7;
 }
  `;

const Line2 = styled.div`
  position: absolute;
  background-color: white;
  height: 1px;
  width: 100%;
  animation: SecondLine linear infinite;
  top: 35rem;
  animation: ${SecondLine} 1s linear infinite;
  opacity: 0.9;
  

  @media (max-width: 1058px) {
    margin-top: -7rem;
  }
  @media (max-width: 768px) {
    margin-top: -14.1rem;
  }
  @media (max-width: 425px) {
    margin-top: -22.5rem;
  }
  @media (max-width: 375px) {
    margin-top: -25rem;
  }
`;
const ThirdLine = keyframes`
0%{
  transform: translateY(0);
  opacity : .7;
 }
100%{
  transform: translateY(20px);
  opacity: .4;
 }
  `;

const Line3 = styled.div`
  position: absolute;
  background-color: white;
  height: 1px;
  width: 100%;
  animation: ThirdLine linear infinite;
  top: 38rem;
  animation: ${ThirdLine} 1s linear infinite;
  opacity: 0.7;
  
  @media (max-width: 1058px) {
    margin-top: -7rem;
  }
  @media (max-width: 768px) {
    margin-top: -14.1rem;
  }
  @media (max-width: 425px) {
    margin-top: -26.5rem;
  }
  @media (max-width: 375px) {
    margin-top: -26.9rem;
  }
`;

const ForthLine = keyframes`
0%{
  transform: translateY(0);
  opacity : .4;
 }
100%{
  transform: translateY(20px);
  opacity: .2;
 }
  `;

const Line4 = styled.div`
  position: absolute;
  background-color: white;
  height: 1px;
  width: 100%;
  animation: ForthLine linear infinite;
  top: 41rem;
  animation: ${ForthLine} 1s linear infinite;
  opacity: 0.4;
  
  @media (max-width: 1058px) {
    margin-top: -7rem;
  }
  @media (max-width: 768px) {
    margin-top: -14.1rem;
  }
  @media (max-width: 425px) {
    display: none;
  }
`;
function BikeRacing() {
  const [image, setImage] = useState(bikeimg);

  return (
    <Root>
      <Container>
        <BgImageWrapper>
          <BgImage src={Background} alt="bgImage" />
        </BgImageWrapper>
        <TextWrapper>
          <SmallText>Welcome to</SmallText> <br />
          <BoldText>
            <FirstBoldText>
              BastionEx <Span>Gaming</Span>
            </FirstBoldText>
          </BoldText>
        </TextWrapper>
        {/* <ChooseColor>
          <CircleText>Choose Color </CircleText> <br />
          <Circle>
            <GreenCircle onClick={() => setImage(bikeimg)}></GreenCircle>
            <RedCircle onClick={() => setImage(RedBike)}></RedCircle>
            <YellowCircle onClick={() => setImage(YellowBike)}></YellowCircle>
          </Circle>
        </ChooseColor> */}
        <BackBuildingimg>
          <Image src={backBuildingimg} alt="backbuildimg" />
        </BackBuildingimg>
        <Roadimg>
          <RoadImage src={Roadbackground} alt="Roading" />
        </Roadimg>
        <LineWrapper>
          <Line1></Line1>
          <Line2></Line2>
          <Line3></Line3>
          <Line4></Line4>
        </LineWrapper>
        <Bikestrip>
          <Bikerace src={bikestp} alt="bikeimg" />
        </Bikestrip>
        <Bikeimg>
          <BikeImage src={image} alt="bikeimg" />
        </Bikeimg>
      </Container>
    </Root>
  );
}

export default BikeRacing;
