import React from "react";
import styled from "styled-components";
import Dropdown from 'react-dropdown';

export const Button = styled.button`
${(p) => p.bgcolor ? `
   background-color:${p.bgcolor};
` : `background:none`};
${(p) => p.fontSize && `
  font-size:${p.fontSize};
`};
${(p) => p.mtop && `margin-top:${p.mtop}`};
${(p) => p.mbottom && `margin-bottom:${p.mbottom}`};
${(p) => p.mleft && `margin-left:${p.mleft}`};
${(p) => p.mright && `margin-right:${p.mright}`};
${(p) => p.top && `padding-top:${p.top}`};
${(p) => p.right && `padding-right:${p.right}`};
${(p) => p.bottom && `padding-bottom:${p.bottom}`};
${(p) => p.left && `padding-left:${p.left}`};
display:flex;
align-items: center;
justify-content:center;
${(p) => p.width && `width:${p.width}`};
${(p) => p.height && `height:${p.height}`};
${(p) => p.color && `color:${p.color}`};
${(p) => p.fontweight ? `font-weight: ${p.fontweight}`:`font-weight:500`};
${(p) => p.border ? `border:1px solid ${p.border}`:`border:none`};
${(p) => p.radius && `border-radius:${p.radius}`};
${(p) => p.opacity && `opacity:${p.opacity}`};
cursor:pointer;
:hover{
  background-color: #232323;
}
`;

export const Container = styled.div`
  width:1160px;
  margin:0 auto;
  display: flex;
  align-items: center;
    ${(p) => p.top && `padding-top:${p.top}`};
    ${(p) => p.bottom && `padding-bottom:${p.bottom}`};
    ${(p) => p.left && `padding-left:${p.left}`};
    ${(p) => p.right && `padding-right:${p.right}`};

  /* align-items:center; */
  @media(max-width:1200px){
    width:100%;
    ${(p) => p.left ? `padding-left:${p.left}` : `padding-left:20px`};
    ${(p) => p.right ? `padding-right:${p.right} ` : `padding-right:20px`};
  }
  @media(max-width:767px){
    ${(p) => p.left && `padding-left:20px`};
    ${(p) => p.right && `padding-right:20px`};
    flex-direction: column;
  }
`;

export const Spacing = styled.div`
${(p) => p.top && `padding-top:${p.top}`};
${(p) => p.bottom && `padding-bottom:${p.bottom}`};
${(p) => p.left && `padding-left:${p.left}`};
${(p) => p.right && `padding-right:${p.right}`};
`;

export const Input = styled.input`
border-radius: 31px;
height: 3rem;
width:100%;
border: 1px solid rgb(128, 128, 128);
background-color: black;
color: rgb(255, 255, 255);
padding-left: 1.5rem;
`;

export const DropdownMenu = styled(Dropdown)`
width:90px;
& .Dropdown-control{
  background:none;
  border:none;
  height:100%;
  display:flex;
  align-items:center;
  color: rgba(0,0,0,0.6);
  position: relative;
  }

  & .Dropdown-arrow{
  top:50%;
  display: none;
  }
  
  & .Dropdown-placeholder{
      font-weight:400;
      font-size:16px;
  }

  & .Dropdown-menu{
    width:240px;
    background:#e7e9eb;
    position: absolute;
    top:50px;
    padding: 20px;
    border:none;
    cursor:pointer;
    color: rgba(0,0,0,0.6);
     & .is-selected{
      color:#00a97c;
    }
    & :hover,:active,:focus{
      color:#00a97c;
    }
    /* & .Dropdown-option{
      color:#000;
    }  */

}

& .Dropdown-option{
    padding:10px 0;
}

`;

