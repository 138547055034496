import React from 'react';
import styled from 'styled-components';
import MapLocation from './MapLocation';
import Header from './Header';
import HeaderNew from './HeaderNew';

const Root = styled.div``;

function ContactUs() {
    return (
        <Root>
            {/* <Header /> */}
            <HeaderNew />
            <MapLocation />
        </Root>
    );
}

export default ContactUs;