import React from "react";
import styled, { keyframes } from "styled-components";
import {
    Button,
  Container,
  Spacing,
} from "../../Component/styledComponents/styledComponents";
import Project1 from "../../Component/Images/Floxypay.jpg";
import Project2 from "../../Component/Images/metaspace.jpg";
import Project3 from "../../Component/Images/thirdProject.jpg";
import Project4 from "../../Component/Images/project4.jpg";
import RoundImageText from "../../Component/Images/round-text.png";
import RoundTextArrow from "../../Component/Images/round-text-arrow.png";
import BackgroundImg from "../../Component/Images/Rectangle.png";
import { useNavigate } from 'react-router-dom';

const Root = styled.div`
  background-color: #fff;
  padding: 40px 0;
  background-image: url(${BackgroundImg});
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding:0 40px;
`;
const Heading = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: white;
  padding-top: 30px;
`;
const Text = styled.h3`
  margin: 0;
  font-weight: 900;
  text-align: center;
  color: white;
  font-size: 3.5rem;
  padding-bottom: 2rem;
`;
const ProjectSectionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  
  @media(max-width:768px){
    width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  }
`;
const Div = styled.div`
  width: calc(50% - 80px);
  padding: 5px 5px 0px 5px;
  display: flex;
  flex-direction: column;
 @media(max-width:1200px){
  width: calc(50% - 40px);
 }
  @media(max-width:768px){
    width: 100%;
  padding: 10px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  margin: -3rem 0rem 0rem 0rem;
  padding: -5rem 0rem 0rem 14rem;
  }
`;

const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  position: relative;
 
`;
const Image = styled.img`
 
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  color: white;
  font-size: 13px;
  padding-left: 1rem;
  margin-top: 0.8rem;
`;

const ButtonWrapper = styled.a`
  position: absolute;
  top: 30%;
  right: -45px;
  color: #fff;
  opacity: 0;
  visibility: hidden;

  ${ProjectWrapper}:hover & {
    opacity: 1;
    visibility: visible;
  }
 
`;
const CircleText = keyframes`
0%{
  transform: rotate(0deg);
}
100%{
  transform: rotate(360deg);
}
`;
const IconImage = styled.img`
  width: 100px;
  animation: ${CircleText} 10s linear infinite;
`;


const Icon = styled.img`
  position: absolute;
  top: 40px;
  right: 40px;
`;

const ButtonWrapper1 = styled.div`
    margin: -3rem 0rem 2rem 31rem;
    @media(max-width:1024px){
      margin-left: 24rem;
    }
    @media(max-width:768px){
      margin: -4.5rem 0rem 0rem 18rem;

    }
    @media(max-width:462px){
      margin-left: 6rem;
    }
`;

const Textbelow = styled.text`
@media(max-width:768px){
  text-align: center;
}
`;

const Space = styled.div`
@media(max-width:768px){
margin-top: -3rem;
}

@media(max-width:425px){
margin-top: -3rem;
padding: 0rem;
}

`;
function ProjectSection() {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
        <Wrapper>
          <Heading>Recently Launched</Heading>
          <Text>Projects</Text>
          <ProjectSectionWrapper>
            <Div>
              <ProjectWrapper>
                <Image src={Project2} alt="Bastionex Infotech Project" />
                <InfoSection>
                  <Textbelow>BastionEx Holdings</Textbelow>
                </InfoSection>
                <ButtonWrapper href="https://metaspacechain.com/" target="_blank">
                  <Icon src={RoundTextArrow} />
                  <IconImage src={RoundImageText} />
                </ButtonWrapper>
              </ProjectWrapper>
              <ProjectWrapper>
                <Image src={Project3} alt="Bastionex Infotech Project" />
                <InfoSection>
                  <Textbelow>Project Name Comes Here - details</Textbelow>
                </InfoSection>
                <ButtonWrapper href="https://shereal.in/" target="_blank">
                  <Icon src={RoundTextArrow} />
                  <IconImage src={RoundImageText} />
                </ButtonWrapper>
              </ProjectWrapper>
            </Div>
            <Div>
              <Space><Spacing top="80px"></Spacing></Space>
              {/* <ProjectWrapper>
                <Image src={Project1} alt="Bastionex Infotech Project" />
                <InfoSection>
                  <Textbelow>Project Name Comes Here - details</Textbelow>
                </InfoSection>
                <ButtonWrapper href="https://www.floxypay.com/" target="_blank">
                  <Icon src={RoundTextArrow} />
                  <IconImage src={RoundImageText} />
                </ButtonWrapper> 
              </ProjectWrapper> */}
              <ProjectWrapper>
                <Image src={Project4} alt="Bastionex Infotech Project" />
                <InfoSection>
                  <Textbelow>Project Name Comes Here - details</Textbelow>
                </InfoSection>
                <ButtonWrapper  href="http://bastionex-holdings.com/" target="_blank">
                  <Icon src={RoundTextArrow} />
                  <IconImage src={RoundImageText} />
                </ButtonWrapper>
              </ProjectWrapper>
            </Div>
          </ProjectSectionWrapper>

        <ButtonWrapper1>
        <Button
          color="white"
          border="#FFFFFF"
          radius="2rem"
          mtop="1.8rem"
          width="9rem"
          height="3rem"
          fontSize="1rem"
          onClick={() => navigate('/our_work')}
          >
            View Portfolio
          </Button>
        </ButtonWrapper1>
        </Wrapper>
      </Container>
    </Root>
  );
}

export default ProjectSection;
