import React from 'react';
import styled from 'styled-components';
import WhatsappChatIcon from "./Images/WhatsappIcon.png";



const Root = styled.div``;
const Container = styled.div``;
const ImageWrapper = styled.div`
position: fixed;
bottom: 40px;
right: 40px;
cursor: pointer;
z-index: 999999;
`;
const Image = styled.img`
width: 65px;
height: 65px;
border-radius: 50%; 
border: 2px solid #25d366;
padding: 5px;
`;

export const WhatsappIcon = () => {
    const openWhatsappChat = () => {
        const phoneNumber = '9557284086';
        const whatsappChatUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappChatUrl, '_blank');
      };
  return (
   <Root>
    <Container>
        <ImageWrapper onClick={openWhatsappChat}>
            <Image src={WhatsappChatIcon} alt="WhatsApp Icon"/>
        </ImageWrapper>

    </Container>
   </Root>
  )
}
