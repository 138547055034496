import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import {
  Container,
  Spacing,
} from "../../../Component/styledComponents/styledComponents";
import Project1 from "../../../Component/Images/Floxypay.jpg";
import Project2 from "../../../Component/Images/metaspace.jpg";
import Project3 from "../../../Component/Images/thirdProject.jpg";
import Project4 from "../../../Component/Images/project4.jpg";
import RoundImageText from "../../../Component/Images/round-text.png";
import RoundTextArrow from "../../../Component/Images/round-text-arrow.png";

const Root = styled.div`
  background-color: #fff;
  padding: 40px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
const H4 = styled.h4`
  margin: 0;
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
`;
const Text = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  text-align: center;
`;
const ProjectSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    padding: 0 45px !important;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0 !important;
  }
`;
const Div = styled.div`
  width: calc(50% - 40px);
  /* padding:20px; */
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const NavSection = styled.div` 
display: flex;
margin-top: 30px;
margin-bottom: 30px;
@media (max-width:767px){ 
display: none;
}
`;
const Link = styled.div`
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 16px;
  font-weight: 600;
  min-width: 80px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  ${(p) =>
    p.selected &&
    `
   border-bottom:3px solid #00a97c;
`};
  @media (max-width: 767px) {
    font-size: 14px;
    min-width: 60px;
  }
`;

const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  position: relative;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;
const Image = styled.img``;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
`;
const InfoCard = styled.div`
  height: 34px;
  padding: 15px;
  border-left: 3px solid #1c8da4;
  margin-top: 8px;
  width: 100%;
  align-items: center;
  display: flex;
  color: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 3px 57px 0 rgba(0, 0, 0, 0.27);
`;
const InfoCardFloxy = styled.div`
  height: 34px;
  padding: 15px;
  border-left: 3px solid #00a97c;
  margin-top: 10px;
  width: 85%;
  display: flex;
  color: rgba(0, 0, 0, 0.6);
  align-items: center;
  box-shadow: 0px 3px 57px 0 rgba(0, 0, 0, 0.27);
`;
const BoldText = styled.div`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 5px;
  align-items: center;
`;

const ButtonWrapper = styled.a`
  position: absolute;
  top: 30%;
  right: -45px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  @media (max-width: 767px) {
    right: 0px;
  }

  ${ProjectWrapper}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

const Logospin = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
`;
const IconImage = styled.img`
  width: 100px;
  animation: ${Logospin} infinite 15s linear;
`;

const Icon = styled.img`
  position: absolute;
  top: 40px;
  right: 40px;
`;

const H1 = styled.h1`
  margin: 0;
  font-size: 1rem;
`;

const Dropdown = styled.div`
  width: 100%;
  display: none;
  justify-content: space-between;
  background-image: linear-gradient(to right, #00a97c, #36c9f9);
  border-radius: 4px;
  padding: 15px;
  @media (max-width: 767px) {
    display: flex;
    position: relative !important;
  }
`;
const DropDownWrpper = styled.div`
  position: relative;
  margin: 20px 0;
`;
const DropdownMenu = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
  border-radius: 4px;
  color: #000;
  z-index: 5;
`;

const List = styled.div`
  font-size: 14px;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 20px;
  :hover {
    box-shadow: 0px 0px 13px 2px rgb(0 0 0 / 5%);
  }
`;
const SpacingDiv = styled.div`
  margin-top: 80px;
  @media (max-width: 767px) {
    margin-top: 0px;
  }
`;
function ProjectSection(props) {
  const [pageLink, setPageLink] = useState("Web");
  const [showOption, setShowOption] = useState(false);
  return (
    <Root>
      <Container>
        <Wrapper>
          {/* <H4>OUR WORK</H4> */}
          {/* <Text>Some of our work that we are proud of</Text> */}
          <NavSection>
            <Link
              selected={pageLink === "Web"}
              onClick={() => setPageLink("Web")}
            >
              <H1> Web Development</H1>
            </Link>
            <Link
              selected={pageLink === "Metaverse"}
              onClick={() => setPageLink("Metaverse")}
            >
              Metaverse Development
            </Link>
            <Link
              selected={pageLink === "Blockchain"}
              onClick={() => setPageLink("Blockchain")}
            >
              Blockchain Development
            </Link>
          </NavSection>
          <DropDownWrpper>
            <Dropdown
              onClick={() => {
                setShowOption(!showOption);
              }}
            >
              Web Development <i className="fa-solid fa-angle-down"></i>
            </Dropdown>
            {showOption && (
              <DropdownMenu>
                <List
                  onClick={() => {
                    setPageLink("Web");
                    setShowOption(!showOption);
                  }}
                >
                  Web Development
                </List>
                <List
                  onClick={() => {
                    setPageLink("Metaverse");
                    setShowOption(!showOption);
                  }}
                >
                  Metaverse Development
                </List>
                <List
                  onClick={() => {
                    setPageLink("Blockchain");
                    setShowOption(!showOption);
                  }}
                >
                  Blockchain Development
                </List>
              </DropdownMenu>
            )}
          </DropDownWrpper>
          {pageLink === "Web" && (
            <ProjectSectionWrapper>
               <Div>
                <SpacingDiv></SpacingDiv>
                <ProjectWrapper>
                  <Image src={Project3} alt="Bastionex Infotech Project" />
                  <InfoSection>
                    <InfoCardFloxy>
                      <BoldText>Project Name</BoldText> - Shereal
                    </InfoCardFloxy>
                    <InfoCard>
                      <BoldText>Tech used </BoldText>- HTML , CSS , JS , PHP
                    </InfoCard>
                  </InfoSection>
                  <ButtonWrapper href="https://shereal.in/" target="_blank">
                    <Icon src={RoundTextArrow} />
                    <IconImage src={RoundImageText} />
                  </ButtonWrapper>
                </ProjectWrapper>

                <ProjectWrapper>
                  <Image src={Project4} alt="Bastionex Infotech Project" />
                  <InfoSection>
                    <InfoCardFloxy>
                      <BoldText>Project Name</BoldText> - Bastionex
                    </InfoCardFloxy>
                    <InfoCard>
                      <BoldText>Tech used </BoldText>- HTML , CSS , JS , PHP
                    </InfoCard>
                  </InfoSection>
                  <ButtonWrapper
                    href="http://bastionex-holdings.com/"
                    target="_blank"
                  >
                    <Icon src={RoundTextArrow} />
                    <IconImage src={RoundImageText} />
                  </ButtonWrapper>
                </ProjectWrapper>
              </Div>
              <Div>
                {/* <ProjectWrapper>
                  <Image src={Project1} alt="Bastionex Infotech Project" />
                  <InfoSection>
                    <InfoCardFloxy>
                      <BoldText>Project Name</BoldText> - FloxyPay
                    </InfoCardFloxy>
                    <InfoCard>
                      <BoldText>Tech used </BoldText>- HTML , CSS , JS , React,
                      Node
                    </InfoCard>
                  </InfoSection>
                  <ButtonWrapper
                    href="https://www.floxypay.com/"
                    target="_blank"
                  >
                    <Icon src={RoundTextArrow} />
                    <IconImage src={RoundImageText} />
                  </ButtonWrapper>
                </ProjectWrapper> */}
                <ProjectWrapper>
                  <Image src={Project2} alt="Bastionex Infotech Project" />
                  <InfoSection>
                    <InfoCardFloxy>
                      <BoldText>Project Name</BoldText> - Metaspace
                    </InfoCardFloxy>
                    <InfoCard>
                      <BoldText>Tech used </BoldText>- HTML , CSS , JS , React,
                      PHP
                    </InfoCard>
                  </InfoSection>
                  <ButtonWrapper
                    href="https://metaspacechain.com/"
                    target="_blank"
                  >
                    <Icon src={RoundTextArrow} />
                    <IconImage src={RoundImageText} />
                  </ButtonWrapper>
                </ProjectWrapper>
              </Div>
             
            </ProjectSectionWrapper>
          )}
          {pageLink === "Metaverse" && (
            <ProjectSectionWrapper>
              <Div>
                <ProjectWrapper>
                  <Image src={Project2} alt="Bastionex Infotech Project" />
                  <InfoSection>
                    <InfoCardFloxy>
                      <BoldText>Project Name</BoldText> - Metaspace
                    </InfoCardFloxy>
                    <InfoCard>
                      <BoldText>Tech used </BoldText>- HTML , CSS , JS , React,
                      PHP
                    </InfoCard>
                  </InfoSection>
                  <ButtonWrapper
                    href="https://metaspacechain.com/"
                    target="_blank"
                  >
                    <Icon src={RoundTextArrow} />
                    <IconImage src={RoundImageText} />
                  </ButtonWrapper>
                </ProjectWrapper>
              </Div>
            </ProjectSectionWrapper>
          )}
          {
            pageLink === "Blockchain" && 
            <ProjectSectionWrapper>
               <Div>
              <SpacingDiv></SpacingDiv>
              <ProjectWrapper>
                <Image src={Project3} alt="Bastionex Infotech Project" />
                <InfoSection>
                  <InfoCardFloxy>
                    <BoldText>Project Name</BoldText> - Shereal
                  </InfoCardFloxy>
                  <InfoCard>
                    <BoldText>Tech used </BoldText>- HTML , CSS , JS , PHP
                  </InfoCard>
                </InfoSection>
                <ButtonWrapper href="https://shereal.in/" target="_blank">
                  <Icon src={RoundTextArrow} />
                  <IconImage src={RoundImageText} />
                </ButtonWrapper>
              </ProjectWrapper>

              <ProjectWrapper>
                <Image src={Project4} alt="Bastionex Infotech Project" />
                <InfoSection>
                  <InfoCardFloxy>
                    <BoldText>Project Name</BoldText> - Bastionex
                  </InfoCardFloxy>
                  <InfoCard>
                    <BoldText>Tech used </BoldText>- HTML , CSS , JS , PHP
                  </InfoCard>
                </InfoSection>
                <ButtonWrapper
                  href="http://bastionex-holdings.com/"
                  target="_blank"
                >
                  <Icon src={RoundTextArrow} />
                  <IconImage src={RoundImageText} />
                </ButtonWrapper>
              </ProjectWrapper>
            </Div>
            <Div>
              {/* <ProjectWrapper>
                <Image src={Project1} alt="Bastionex Infotech Project" />
                <InfoSection>
                  <InfoCardFloxy>
                    <BoldText>Project Name</BoldText> - FloxyPay
                  </InfoCardFloxy>
                  <InfoCard>
                    <BoldText>Tech used </BoldText>- HTML , CSS , JS , React,
                    Node
                  </InfoCard>
                </InfoSection>
                <ButtonWrapper
                  href="https://www.floxypay.com/"
                  target="_blank"
                >
                  <Icon src={RoundTextArrow} />
                  <IconImage src={RoundImageText} />
                </ButtonWrapper>
              </ProjectWrapper> */}
              <ProjectWrapper>
                <Image src={Project2} alt="Bastionex Infotech Project" />
                <InfoSection>
                  <InfoCardFloxy>
                    <BoldText>Project Name</BoldText> - Metaspace
                  </InfoCardFloxy>
                  <InfoCard>
                    <BoldText>Tech used </BoldText>- HTML , CSS , JS , React,
                    PHP
                  </InfoCard>
                </InfoSection>
                <ButtonWrapper
                  href="https://metaspacechain.com/"
                  target="_blank"
                >
                  <Icon src={RoundTextArrow} />
                  <IconImage src={RoundImageText} />
                </ButtonWrapper>
              </ProjectWrapper>
            </Div>
           
          </ProjectSectionWrapper>
          }
        </Wrapper>
      </Container>
    </Root>
  );
}

export default ProjectSection;
