import React from "react";
import styled from "styled-components";
import Mapbg from "../../Component/Images/ContactUsMapimage.png";
import TajImage from "../../Component/Images/contactUsIcon1.png";
import contactUsIcon2 from "../../Component/Images/contactUsIcon2.png";
import DubaiIcon from "../../Component/Images/dubai-icon.png";
import { Container } from "../../Component/styledComponents/styledComponents";

const Root = styled.div`
  height: 35rem;
  position: relative;
  background-color: #000;
  display: flex;
  background-image: url(${Mapbg});
  background-position: -100px 0px;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
  }
  @media (max-width: 543px) {
    height: 100%;
    padding: 30px 0;
  }
`;
const ImageWrapper = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const LeftTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  min-width: 60%;
  @media (max-width: 1100px) {
    width: 60%;
    min-width: 60%;
  }
  @media (max-width: 920px) {
    width: 70%;
    min-width: 70%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Heading = styled.div`
  font-size: 36px;
  background-image: linear-gradient(60deg, #00dea3, #037474);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;
  margin-bottom: 20px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const CardSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  @media (max-width: 543px) {
    flex-direction: column;
  }
`;
const Card = styled.div`
  border: solid 1px rgba(48, 184, 148, 0.53);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  color: #fff;
  padding: 10px 10px;
  width: calc(33.33% - 8px);
  background-color: #181818;
  transition: all 2s ease;

  :hover {
    background-color: #fff;
    border: 1px solid #fff;
    color: #000;
  }
  @media (max-width: 543px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const Image = styled.img`
  margin-bottom: 10px;
`;
const Text = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
`;
function MapLocation() {
  return (
    <Root>
      <Container>
        <ImageWrapper></ImageWrapper>
        <LeftTextWrapper>
          <Heading>
            Our <Bold>global</Bold> presence
          </Heading>
          <CardSection>
          <Card>
              <Image src={TajImage} alt="Bastionex Infotech Dubai" />
              <Text>
                <Bold>India</Bold>
              </Text>
              <Text>B-2, Sector-4, Noida, Gautam Buddh Nagar, Uttar Pradesh, India, 201301</Text>
              {/* <Text>+971566258725</Text> */}
            </Card>

            <Card>
              <Image src={DubaiIcon} alt="Bastionex Infotech Dubai" />
              <Text>
                <Bold>Dubai</Bold>
              </Text>
              <Text>Office 121, KML building, Al meydan road, Dubai, UAE</Text>
              {/* <Text>+971566258725</Text> */}
            </Card>
            <Card>
              <Image src={contactUsIcon2} alt="Bastionex Infotech Australia" />
              <Text>
                <Bold>Australia</Bold>
              </Text>
              <Text>81-83 Campbell Street Surry Hills NSW 2010 Australia</Text>
              {/* <Text>(07) 3184 0904</Text> */}
            </Card>
          </CardSection>
        </LeftTextWrapper>
      </Container>
    </Root>
  );
}

export default MapLocation;
