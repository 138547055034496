import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "../../Component/styledComponents/styledComponents";
import axios from "axios";
import SucessPopup from "../../Component/SucessPopup";
import PanelsIconEmail from "../../Component/Images/panels__icon_email.svg";
import PanelsIconSupport from "../../Component/Images/panels__icon_support.svg";
import EmailAlreadyExist from "../../Component/EmailAlreadyExist";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import CircularLoader from "../../Component/CircularLoader";
import { environmentVariables } from "../../config/env.config";
import { useNavigate } from "react-router-dom";

const baseUrl = environmentVariables?.apiUrl;
const Root = styled.div`
  display: flex;
  padding: 150px 0 80px 0;
  @media (max-width: 767px) {
    width: 100%;
    padding: 100px 0 30px 0;
  }
`;
const LeftSideWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Text = styled.text`
  font-weight: bold;
`;
const SmallHeading = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  color: #00a97c;
`;

const RightSideWrapper = styled.div`
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  min-width: 100%;
  height: 2.8rem;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  padding: 10px;
`;
const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid #dbdbdb;
  height: 4.8rem;
  padding: 10px 10px;
`;
const Form = styled.form`
  background-color: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: -6.1px 3.5px 18px 0 rgba(0, 0, 0, 0.16);
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

// const ErrorMessage = styled.div`
//   color: red;
//   padding-left: 20px;
//   padding-top: 10px;
// `;

const Heading = styled.h1`
  color: #000000;
  font-size: 55px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: left;
  width: 100%;
  @media (max-width: 925px) {
    font-size: 35px;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
`;
const IconSection = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  @media (max-width: 767px) {
    margin-top: 0px;
  }
`;
const Icon = styled.img`
  width: 90px;
  margin-bottom: 15px;
  @media (max-width: 500px) {
    width: 60px;
  }
`;

const InputWrapper = styled.div`
  width: calc(50% - 10px);
  margin-bottom: 20px;
  @media (max-width: 925px) {
    width: 100%;
  }
`;
const InputWrapper2 = styled.div`
  /* width: calc(50% - 10px); */
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 925px) {
    width: 100%;
  }
`;
const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 925px) {
    flex-direction: column;
  }
`;
const Label = styled.div`
  margin-bottom: 10px;
  color: #4f4f4f;
  font-size: 14px;
  display: flex;
  justify-content: left;
  width: 100%;
`;

const Button = styled.button`
  // background-color: #30B894;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 2.6rem;
  color: white;
  font-weight: 500;
  border: none;
  // border-radius: 12px;
  // cursor: pointer;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  background-color: ${(props) => (props.disabled ? "#a0a0a0" : "#30B894")};
`;
const FormHeadText = styled.div`
  color: #202020;
  font-weight: bold;
  font-size: 18px;
`;
const HeadText = styled.div`
  color: #4f4f4f;
  font-size: 16px;
  margin: 15px 0 30px 0;
  width: 100%;
  text-align: left;
`;

const H1 = styled.h1`
  margin: 0;
  font-size: 55px;
`;
const Select = styled.select`
  width: 100%;
  background: white;
  color: gray;
  padding-left: 5px;
  font-size: 14px;
  border: 1px solid #dbdbdb;
  padding: 14px 10px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
    /* padding: 5px 0; */
    border-radius: 10px;
  }
`;

const ErrorMsg = styled.div`
  font-size: 12px;
  color: #ff0000c2;
  padding-top: 2px;
`;

const HeaderNew = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [profession, setProfession] = useState("");
  const [select, setSelect] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Name must contain only letters")
      .min(3, "must be at least 3 char")
      .max(50, " must be less than 50 char")
      .required("Full Name is required"),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Accept only valid email"
      )
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .min(10, "Required Valid Phone number")
      .max(12, " Not Accept more than 12 Number")
      .required("Contact Number is required"),

    companyname: Yup.string()
      .min(5, "must be at least 5 char")
      .max(100, " must be less than 100 char")
      .required("Company Name is required"),

    select: Yup.string().required("Company Size is required"),

    profession: Yup.string()
      .min(3, "must be at least 3 char")
      .max(100, " must be less than 50 char")
      .required("Designation is required"),
    message: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: name,
      email: email,
      phone: phone,
      select: select,
      companyname: companyname,
      profession: profession,
      message: message,
    },
    validationSchema,
    // onSubmit: (values) => {
    //   console.log(values);
    // },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const phoneAsString = String(formik.values.phone);
    const urlParams = new URLSearchParams(window.location.search);
    const queryString = urlParams.toString();
    let config = {
      method: "post",
      url: `${baseUrl}api/v1/info/saveContactInfo`,
      data: { ...formik.values, phone: phoneAsString, keywords: queryString },
    };

    axios
      .request(config)
      .then((response) => {
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setCompanyname("");
        setProfession("");
        setSelect("");
        setIsSubmit(false);
        navigate("/thankyou");
        // Swal.fire({
        //   title: "Success",
        //   text: `Thank you for reaching out to us! 
        //   Team will get back to you soon`,
        //   icon: "success",
        // });
        formik.resetForm();
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmit(false);
        Swal.fire({
          icon: "error",
          text: "Something went wrong!",
        });
      });
  };

  return (
    <Root>
      <Container>
        <LeftSideWrapper>
          <SmallHeading>Contact Us for Consultation Call</SmallHeading>

          <Heading>
            <H1>Have a question?</H1>
          </Heading>
          <HeadText>We are here to rescue!</HeadText>

          <IconSection>
            <IconWrapper>
              <Icon src={PanelsIconEmail} alt="Contact Bastionex Infotech" />
              <Text>info@bastionex.net</Text>
            </IconWrapper>
            <IconWrapper>
              <Icon src={PanelsIconSupport} alt="Contact Bastionex Infotech" />
              <Text>+91 9557284086</Text>
            </IconWrapper>
          </IconSection>
        </LeftSideWrapper>
        <RightSideWrapper>
          <Form>
            <FormHeadText>Contact Us for Consultation Call</FormHeadText>
            <HeadText>
              Please fill the form below and someone from BastionEx will respond
              soon. Thank You!
            </HeadText>
            <FormWrapper>
              <InputWrapper>
                <Label>Full Name*</Label>
                <Input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.name && formik.errors.name && (
                  <ErrorMsg>{formik.errors.name}</ErrorMsg>
                )}
              </InputWrapper>
              <InputWrapper>
                <Label>Email*</Label>
                <Input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.email && formik.errors.email && (
                  <ErrorMsg>{formik.errors.email}</ErrorMsg>
                )}
              </InputWrapper>
            </FormWrapper>
            <FormWrapper>
              <InputWrapper>
                <Label>Contact Number*</Label>
                <Input
                  type="number"
                  name="phone"
                  placeholder="Phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.phone && formik.errors.phone && (
                  <ErrorMsg>{formik.errors.phone}</ErrorMsg>
                )}
              </InputWrapper>
              <InputWrapper>
                <Label>Company Name*</Label>
                <Input
                  type="text"
                  placeholder="Company Name"
                  name="companyname"
                  value={formik.values.companyname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.companyname && formik.errors.companyname && (
                  <ErrorMsg>{formik.errors.companyname}</ErrorMsg>
                )}
              </InputWrapper>
            </FormWrapper>
            <FormWrapper>
              <InputWrapper>
                <Label>Company Size*</Label>
                <Select
                  name="select"
                  value={formik.values.select}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                >
                  <option value="" hidden>
                    Choose an Option
                  </option>
                  <option value="10">0-10 Employees</option>
                  <option value="25">11-25 Employees</option>
                  <option value="50">26-50 Employees</option>
                  <option value="100">51-100 Employees</option>
                  <option value="150">100+ Employees</option>
                </Select>
                {formik.touched.select && formik.errors.select && (
                  <ErrorMsg>{formik.errors.select}</ErrorMsg>
                )}
              </InputWrapper>
              <InputWrapper>
                <Label>Designation*</Label>
                <Input
                  type="text"
                  placeholder="Designation"
                  name="profession"
                  value={formik.values.profession}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.profession && formik.errors.profession && (
                  <ErrorMsg>{formik.errors.profession}</ErrorMsg>
                )}
              </InputWrapper>
            </FormWrapper>
            <FormWrapper>
              <InputWrapper2>
                <Label>What are you looking for ?</Label>
                <TextArea
                  type="text"
                  placeholder="Message...."
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows={5}
                  cols={50}
                />
                {formik.touched.message && formik.errors.message && (
                  <ErrorMsg>{formik.errors.message}</ErrorMsg>
                )}
              </InputWrapper2>
            </FormWrapper>

            <Button onClick={handleSubmit} disabled={!formik.isValid || !formik.dirty || isSubmit}>
  {isSubmit ? <CircularLoader size={20} /> : "SUBMIT"}
</Button>

          </Form>
        </RightSideWrapper>
      </Container>
    </Root>
  );
};

export default HeaderNew;
