import React from "react";
import styled from "styled-components";
import BgImage from "../../Component/Images/bg.jpg";
import { Container } from "../../Component/styledComponents/styledComponents";
import GreenArrowIcon from "../../Component/Images/green-arrow.png";
import RightSideImage from "../../Component/Images/payiza-icon.png";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
  background-image: url(${BgImage});
  padding: 60px 0;
  @media (max-width: 767px) {
    padding: 30px 0;
  }
`;
const LeftsideWrapper = styled.div`
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const RightsideWrapper = styled.div`
  width: 50%;
  text-align: right;
  @media (max-width: 767px) {
    width: 100%;
    text-align: left;
  }
`;

const HeadText = styled.div`
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: bold;
`;
const Heading = styled.h2`
  margin: 0;
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 15px;
`;
const Para = styled.div`
  font-size: 14px;
  line-height: 28px;
`;
const Wrapper = styled.div`
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const Icon = styled.img`
  margin-top: 20px;
`;

const Image = styled.img`
  max-width: 100%;
`;
function SollutionSection(props) {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
        <Wrapper>
          <LeftsideWrapper>
            <HeadText>Tired of seeing the worst designed</HeadText>
            <HeadText>and developed products?</HeadText>
            <Heading>BastionEx have solutions for you....</Heading>
            <Para>
              We bring end-to-end tailored services with latest tools and
              intelligent technologies to add an extra layer of protection for
              businesses.
            </Para>
            <Icon
              src={GreenArrowIcon}
              onClick={() => navigate("/contact_us")}
            />
          </LeftsideWrapper>
          <RightsideWrapper>
            {/* <Image src={RightSideImage} /> */}
          </RightsideWrapper>
        </Wrapper>
      </Container>
    </Root>
  );
}

export default SollutionSection;
